import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next-intl@3.25.3_next@15.1.6_react@19.0.0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next-intl@3.25.3_next@15.1.6_react@19.0.0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.8_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@19.0.0_react@19.0.0/node_modules/next/dist/lib/metadata/metadata-boundary.js");
